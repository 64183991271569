import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  forwardRef,
  useCallback,
} from 'react';
import { silencePromise } from 'src/utils/silence-promise';
import { MediaControls } from 'src/components/common/MediaControls';
import { useMotionOptOutContext } from 'src/components/contexts/MotionOptOutContext';
import { legacy__trackEvent } from 'src/services/analytics-service';
import { EVENTS } from 'src/services/analytics-constants';

export const VideoControls = forwardRef(function VideoControls(
  { className, onClick, videoElement, videoRef, autoplay = true, onError, style = {} },
  ref
) {
  const { optOut, setOptOut } = useMotionOptOutContext();
  const [videoIsPaused, setVideoIsPaused] = useState(!autoplay);
  const [videoWasPreviouslyPaused, setVideoWasPreviouslyPaused] = useState(false);
  const playPromiseActiveRef = useRef(false);

  const getVideoEl = useCallback(() => {
    return videoRef?.current || videoElement;
  }, [videoElement, videoRef]);

  const handleClick = () => {
    onClick?.(!videoIsPaused);
    togglePlay();
    legacy__trackEvent(EVENTS.VIDEO_CONTROLS_CLICKED, {
      type: 'Button',
      label: videoIsPaused ? 'play' : 'pause',
    });
  };

  const isVideoPlaying = video => {
    return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
  };

  // Videos opt out play/pause handler
  useEffect(() => {
    const videoEl = getVideoEl();
    // checks every frame and waits for async waiterRef.current value to equal false
    const waitUntilRefIsFalse = (waiterRef, callback) => {
      if (!waiterRef.current) {
        if (callback) callback();
      } else {
        // being precise here, because even a couple frames of playback is usually ugly
        window.requestAnimationFrame(() => {
          waitUntilRefIsFalse(waiterRef, callback);
        });
      }
    };

    // only need to manually play the video if it had previously been paused
    // in other words, we can assume autoplay is doing its job
    if (!optOut && autoplay && videoWasPreviouslyPaused) {
      // ensure video will play on device
      const playPromiseRef = videoEl?.play();
      if (playPromiseRef) {
        // manually tracking promise status
        playPromiseActiveRef.current = true;
        playPromiseRef
          .then(() => {
            playPromiseActiveRef.current = false;
            setVideoIsPaused(false);
          })
          .catch(error => {
            // likely detecting low power mode
            if (onError) onError(error);
            playPromiseActiveRef.current = false;
          });
      }
    }

    if (optOut && autoplay) {
      // video.pause() will set off an error while the initial video.play() promise
      // is active/unresolved, so this throttles until promise has been resolved.
      waitUntilRefIsFalse(playPromiseActiveRef, () => {
        if (videoEl) videoEl.pause();
        setVideoIsPaused(true);
        setVideoWasPreviouslyPaused(true);
      });
    }
  }, [autoplay, getVideoEl, onError, optOut, videoRef, videoWasPreviouslyPaused]);

  const togglePlay = () => {
    const videoEl = getVideoEl();
    if (isVideoPlaying(videoEl)) {
      setOptOut(true);
      videoEl.pause();
    } else {
      setOptOut(false);
      silencePromise(videoEl.play());
    }
  };

  useLayoutEffect(() => {
    let isMounted = true;

    const handlePlay = () => {
      if (isMounted) setVideoIsPaused(false);
    };
    const handlePause = () => {
      if (isMounted) setVideoIsPaused(true);
    };

    const videoEl = getVideoEl();
    if (videoEl) {
      // only update visual state when video player has changed state internally
      videoEl.addEventListener('play', handlePlay);
      videoEl.addEventListener('pause', handlePause);
    }

    return () => {
      isMounted = false;

      if (videoEl) {
        videoEl.pause();
        videoEl.removeEventListener('play', handlePlay);
        videoEl.removeEventListener('pause', handlePause);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MediaControls
      className={className}
      onPlay={handleClick}
      onPause={handleClick}
      isPaused={videoIsPaused}
      style={style}
      ref={ref}
    />
  );
});
