/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect } from 'react';
import { CODEC_TYPE_H264, CODEC_TYPE_H265, CODEC_TYPE_WEBM } from 'src/utils/contentful';
import { classNames } from 'src/utils/css';
import { useMotionOptOutContext } from 'src/components/contexts/MotionOptOutContext';
import { VideoControls } from 'src/components/common/VideoControls';
import './index.scss';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';

export const VideoPlayer = ({
  h265Src,
  webmSrc,
  h264Src,
  posterSrc,
  alt = '',
  lazyload = true,
  lazyloadThreshold = '100px',
  autoplay = true,
  loop = true,
  muted = true,
  controls = true,
  backgroundColor,
  preload = 'auto',
  releaseAspectRatio,
  objectPosition = '50% 50%',
  objectFit = 'cover',
  onControlsDisplayChange,
  onLoad,
  onError,
}) => {
  const videoElRef = useRef();
  const controlsElRef = useRef();
  const { isFr } = useTranslationContext();
  const { optOut } = useMotionOptOutContext();

  // hide from screen readers if no alt text provided
  const ariaHidden = alt === '';

  // background color can help smooth the transition to poster/video loaded
  const wrapperStyle = { backgroundColor } ?? {};

  const loadSources = _optOut => {
    let shouldLoadVideo = false;
    /* eslint-disable no-unused-expressions */
    [...(videoElRef.current?.children ?? [])].forEach(videoSource => {
      if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
        if (videoSource.getAttribute('src') === null) {
          videoSource.src = videoSource.dataset.src;
          shouldLoadVideo = true;
        }
      }
    });
    shouldLoadVideo && videoElRef.current?.load();
    if (_optOut) videoElRef.current.pause();
    /* eslint-enable no-unused-expressions */
  };

  useEffect(() => {
    let scrollObserver;
    if (lazyload) {
      scrollObserver = new IntersectionObserver(
        entries => {
          const isIntersecting = entries?.[0]?.isIntersecting;
          if (isIntersecting) {
            loadSources(optOut);
          }
        },
        { rootMargin: `${lazyloadThreshold} 0px` }
      );

      if (videoElRef.current) {
        scrollObserver.observe(videoElRef.current);
      }
      return () => {
        scrollObserver.disconnect();
      };
    }
    return () => {
      if (scrollObserver?.disconnect) scrollObserver.disconnect();
    };
  }, [videoElRef, lazyload, lazyloadThreshold, optOut]);

  useEffect(() => {
    if (controls && onControlsDisplayChange) onControlsDisplayChange(true);
    // no controls means no MotionOptOut, so handling here
    if (optOut && !controls) videoElRef.current.pause();
  }, [videoElRef, optOut, controls, onControlsDisplayChange]);

  const handlePlaybackError = error => {
    if (controls && onControlsDisplayChange) onControlsDisplayChange(false);
    if (onError) onError(`Error: ${error}`);
  };

  const handleLoadedData = () => {
    if (onLoad) onLoad();
  };

  // controls normally handles autoplay, except for when there aren't any
  const shouldHandleAutoplayInternally = !controls && autoplay && !optOut ? true : autoplay;
  const videoSourceList = [h265Src, webmSrc, h264Src];

  const renderVideoSources = () => {
    const sourceToCodecArray = [CODEC_TYPE_H265, CODEC_TYPE_WEBM, CODEC_TYPE_H264, null];

    return videoSourceList.map(
      (srcAttr, index) =>
        srcAttr && (
          <source
            key={srcAttr}
            type={sourceToCodecArray[index]}
            {...(lazyload ? { 'data-src': srcAttr } : { src: srcAttr })}
          />
        )
    );
  };

  const sourceSlug = videoSourceList
    .filter(videoSrc => !!videoSrc)?.[0]
    ?.split('/')
    ?.slice(-2)
    ?.join('')
    ?.replace('.', '');
  const descriptionId = alt ? `desc-${sourceSlug}` : null;

  const groupLabel = isFr ? 'vidéo' : 'video';

  return (
    <div style={wrapperStyle} className="VideoPlayer">
      <video
        className={classNames('VideoPlayer__video')}
        style={{
          objectPosition,
          ...(releaseAspectRatio && { objectFit }),
        }}
        ref={videoElRef}
        aria-hidden={ariaHidden}
        autoPlay={shouldHandleAutoplayInternally}
        poster={posterSrc}
        muted={muted}
        loop={loop}
        preload={preload}
        disableRemotePlayback
        playsInline
        onLoadedMetadata={handleLoadedData}
        aria-label={groupLabel}
        aria-describedby={descriptionId}
      >
        {renderVideoSources()}
      </video>
      {controls && (
        <div
          ref={controlsElRef}
          className="VideoPlayer__controlsWrapper"
          data-default-video-controls
        >
          <VideoControls
            autoplay={autoplay}
            videoRef={videoElRef}
            onError={handlePlaybackError}
          />
        </div>
      )}
      {alt && (
        <span hidden id={descriptionId}>
          {alt}
        </span>
      )}
    </div>
  );
};
/* eslint-enable jsx-a11y/media-has-caption */
