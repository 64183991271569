'use client';

import React, { forwardRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import NextImage from 'next/image';
import { useUserAttributesContext } from 'src/components/contexts/UserAttributesContext';
import { aspectRatioToValidCssString, classNames } from 'src/utils/css';
import { BLEED_CLASSES, MediaWithCaption } from 'src/components/common/MediaWithCaption';
import { VideoPlayer } from '../video-player';
import { media_, mediaImage_, mediaRoundCorners_ } from './media.module.scss';

const MediaBase = forwardRef(function MediaBase(
  {
    className = '',
    gatsbyImageData,
    imageData,
    h265Src,
    webmSrc,
    h264Src,
    posterSrc,
    autoPlayVideo = true,
    shouldLoopVideo = true,
    backgroundColor,
    aspectRatio,
    releaseAspectRatio,
    lazyload = true,
    lazyloadThreshold,
    controls: controlsPreference,
    alt,
    onControlsDisplayChange,
    onLoad,
    objectPosition = '50% 50%',
    objectFit = 'cover',
    roundCorners = false,
    ...rest
  },
  ref
) {
  const { isPowerSaveMode } = useUserAttributesContext();
  const mediaPriorityIsVideo = h265Src || webmSrc || h264Src;
  const controls = controlsPreference ?? shouldLoopVideo;

  // listen for video error, so we can fallback to image
  const [videoError, setVideoError] = useState(false);
  const [controlsActive, setControlsActive] = useState(controls);
  const onVideoPlaybackError = useCallback(() => {
    setVideoError(true);
  }, [setVideoError]);

  const handleControlsDisplayChange = isActive => {
    if (onControlsDisplayChange) onControlsDisplayChange(isActive);
    setControlsActive(isActive);
  };

  const handleLoadComplete = () => {
    if (onLoad) onLoad();
  };

  const handleGatsbyImageStartLoad = ({ wasCached }) => {
    if (wasCached) handleLoadComplete();
  };

  const shouldRenderVideo = mediaPriorityIsVideo && !videoError && !isPowerSaveMode;

  return (
    <div
      ref={ref}
      className={classNames(
        'Media',
        media_,
        controlsActive && 'Media--controls-active',
        className,
        roundCorners && mediaRoundCorners_
      )}
      style={{ background: backgroundColor }}
      {...rest}
    >
      <div
        className="mediaAssetWrapper"
        style={
          !releaseAspectRatio && aspectRatio
            ? { aspectRatio: aspectRatioToValidCssString(aspectRatio) }
            : { height: '100%' }
        }
      >
        {shouldRenderVideo && (
          <VideoPlayer
            h265Src={h265Src}
            webmSrc={webmSrc}
            h264Src={h264Src}
            posterSrc={posterSrc}
            autoplay={autoPlayVideo}
            loop={shouldLoopVideo}
            alt={alt}
            lazyload={lazyload}
            lazyloadThreshold={lazyloadThreshold}
            controls={controls}
            releaseAspectRatio={releaseAspectRatio}
            onLoad={handleLoadComplete}
            onError={onVideoPlaybackError}
            onControlsDisplayChange={handleControlsDisplayChange}
            objectPosition={objectPosition}
            objectFit={objectFit}
          />
        )}
        {!shouldRenderVideo && gatsbyImageData && (
          <GatsbyImage
            className={classNames('Media__image', mediaImage_)}
            image={gatsbyImageData}
            loading={lazyload ? 'lazy' : 'eager'}
            onLoad={handleLoadComplete}
            onStartLoad={handleGatsbyImageStartLoad}
            objectPosition={objectPosition}
            objectFit={objectFit}
            alt={alt}
          />
        )}
        {!shouldRenderVideo && imageData && (
          <NextImage
            {...imageData}
            className={classNames('Media__image', mediaImage_)}
            onLoad={handleLoadComplete}
            alt={alt}
            priority={!lazyload}
            style={{ objectFit, objectPosition }}
          />
        )}
      </div>
    </div>
  );
});

export const Media = forwardRef(function Media(
  { caption, bleed, ...rest },
  ref
) {
  return caption ? (
    <MediaWithCaption
      media={<MediaBase ref={ref} {...rest} />}
      caption={caption}
      bleed={bleed}
    />
  ) : (
    <MediaBase ref={ref} {...rest} />
  );
});

export const MediaShape = {
  gatsbyImageData: PropTypes.shape({}),
  videoSrc: PropTypes.string,
  videoFallbackSrc: PropTypes.string,
  posterSrc: PropTypes.string,
  aspectRatio: PropTypes.string,
  alt: PropTypes.string,
  autoPlayVideo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  backgroundColor: PropTypes.string,
  h265Src: PropTypes.string,
  webmSrc: PropTypes.string,
  h264Src: PropTypes.string,
  bleed: PropTypes.oneOf(Object.keys(BLEED_CLASSES)),
};
