export const translations = {
  'en-ca': {
    'media_controls::play': 'Play video',
    'media_controls::pause': 'Pause video',
  },
  'fr-ca': {
    'media_controls::play': 'Lire la vidéo',
    'media_controls::pause': 'Pause la vidéo',
  },
};
